<template>
  <div>
    <b-card no-body style="height: 100%;" class="work-card" @click="goTo">
      <b-card-header>
        <b-card-title>
          <h4 style="color: #555;">{{ workResult.companyName }}</h4>
          <small class="text-muted">
            {{ workResult.workContractorAdminName }},
            {{ workResult.workContractorAdminTel }}
          </small>
        </b-card-title>
        <b-card-title class="text-primary" style="cursor: default">
          <div class="d-flex">
            <strong>{{ workResult.workCode }}</strong>

<!--            <div class="dropdown" v-if="stateDropdownBtn">
              <b-dropdown variant="link" no-caret toggle-class="p-0" right>
                <template #button-content>
                  <feather-icon icon="MoreVerticalIcon" size="17" class="align-middle text-body"/>
                </template>
                <b-dropdown-item @click="goToEdit">
                  {{ $t('general.btnEdit') }}
                </b-dropdown-item>
                <b-dropdown-divider v-if="stateDelete"/>
                <b-dropdown-item @click="confirmDelete" v-if="stateDelete">
                  {{ $t('general.btnDelete') }}
                </b-dropdown-item>
              </b-dropdown>
            </div>-->
          </div>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-card-text class="mb-2">
          {{ workResult.workScope }}
        </b-card-text>
      </b-card-body>

      <b-card-footer style="border: 0">
        <div class="d-flex justify-content-between align-items-end">
          <div class="font-small-2">{{ workResult.workDateMerge }}</div>
          <div>
            <b-badge :variant="badgeVariant(workResult.workStep)" style="margin-right: 0.2rem;"
                     v-b-tooltip.hover.top="cardBadgeDescription"
            >
              <feather-icon :icon="cardBadgeIcon" />
            </b-badge>

            <b-badge :variant="badgeVariant(workResult.workStep)"
                     v-if="workResult.workStep === 20 && workResult.workLatestIsCheckIn === 'Y'"
                     v-b-tooltip.hover.top="$t('work.duringWork')"
            >
              {{ workStatusName }}
              <b-spinner small type="grow" variant="success" style="margin-left: 0.5rem;"/>
            </b-badge>
            <b-badge :variant="badgeVariant(workResult.workStep)" v-else>
              {{ workStatusName }}
            </b-badge>
          </div>
        </div>
      </b-card-footer>
    </b-card>

    <b-modal
        ref="modal-choose-action"
        cancel-variant="outline-secondary"
        hide-footer
        :title="$t('work.headerChooseAction')"
        centered
    >
      <b-row>
        <b-col>
          <b-button variant="warning" squared block style="height: 100px"
                    :to="{name: 'pageWorkApproval', params: {id: workIdSelected}}"
          >
            <feather-icon icon="CheckIcon" size="40"/>
            <div>{{ $t('general.btnApprove') }}</div>
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="primary" squared block style="height: 100px"
                    :to="{name: 'pageWorkOperation', params: {id: workIdSelected}}"
          >
            <feather-icon icon="Edit3Icon" size="40"/>
            <div>{{ $t('work.btnOperate') }}</div>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>

    <b-modal
        ref="modal-choose-edit-action"
        cancel-variant="outline-secondary"
        hide-footer
        :title="$t('work.headerChooseAction')"
        centered
        size="lg"
    >
      <b-row>
        <b-col>
          <b-button variant="info" squared block style="height: 100px"
                    :to="{name: 'pageWorkView', params: {id: workIdSelected}}"
          >
            <feather-icon icon="EyeIcon" size="40"/>
            <div class="mt-1">{{ $t('general.btnView') }}</div>
          </b-button>
        </b-col>
        <b-col v-if="stateApprove">
          <b-button variant="warning" squared block style="height: 100px"
                    :to="{name: 'pageWorkApproval', params: {id: workIdSelected}}"
          >
            <feather-icon icon="CheckIcon" size="40"/>
            <div class="mt-1">{{ $t('general.btnApprove') }}</div>
          </b-button>
        </b-col>
        <b-col>
          <b-button variant="primary" squared block style="height: 100px"
                    :to="{name: 'pageWorkEditStep1', params: {id: workIdSelected}}"
          >
            <feather-icon icon="Edit3Icon" size="40"/>
            <div class="mt-1">{{ $t('general.btnEdit') }}</div>
          </b-button>
        </b-col>
        <b-col v-if="stateDelete">
          <b-button variant="danger" squared block style="height: 100px"
                    @click="confirmDelete"
          >
            <feather-icon icon="Trash2Icon" size="40"/>
            <div class="mt-1">{{ $t('general.btnDelete') }}</div>
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardFooter, BBadge, BCardText, BCardBody, BButton, BDropdown, BDropdownItem, BDropdownDivider, BSpinner, VBTooltip
} from 'bootstrap-vue'
import tableMixins from "@/mixins/tableMixins"
import formMixins from "@/mixins/formMixins"
import WorkService from "@/services/WorkService"

let workService = new WorkService()

export default {
  name: "WorkCard",
  mixins: [tableMixins, formMixins],
  components: {
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BButton,
    BBadge,
    BCardHeader,
    BCardText,
    BCardBody,
    BCardFooter,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    workResult: {
      required: true,
      type: Object
    }
  },
  beforeDestroy() {
    this.isEnabledDropdown = false
  },
  data: () => ({
    userData: JSON.parse(localStorage.getItem('userData')),
    workIdSelected: '',
    isEnabledDropdown: false,
  }),
  watch: {
    isEnabledDropdown() {
      setTimeout(() => {
        this.isEnabledDropdown = false
      }, 500)
    }
  },
  computed: {
    workStatusName() {
      if (this.workResult && this.workResult.workStepResult) {
        return this.switchLanguage(this.workResult.workStepResult.statusNameTh, this.workResult.workStepResult.statusNameEn)
      }
    },
    stateDropdownBtn() {
      return !(!this.stateEdit && !this.stateDelete)
    },
    stateEdit() {
      return (this.workResult.workStep === 1 || this.workResult.workStep === 2) && (this.workResult.actionBy === this.userData.userId ||
          this.workResult.workPermissionWorkOwner.includes(this.userData.userId))
    },
    stateDelete() {
      // Contractor Admin, Work Owner, Safety

      return this.workResult.workStep === 2 && (this.workResult.actionBy === this.userData.userId || this.workResult.workPermissionWorkOwner.includes(this.userData.userId) || this.userData.userGroupId === 4)
    },
    stateApprove() {
      let {workStepResult} = this.workResult
      return workStepResult.groupStatusType === 'approve' && (
          ([2, 6].includes(this.workResult.workStep) && this.workResult.workPermissionWorkOwner.includes(this.userData.userId)) ||
          ([3, 7].includes(this.workResult.workStep) && this.workResult.workPermissionAreaOwner.includes(this.userData.userId)) ||
          ([4, 8].includes(this.workResult.workStep) && this.workResult.workPermissionManager.includes(this.userData.userId)) ||
          ([5, 9].includes(this.workResult.workStep) && workStepResult.permission.includes(this.userData.userGroupId))
      )
    },
    cardBadgeIcon() {
      return this.checkPermission().icon
    },
    cardBadgeDescription() {
      return this.checkPermission().description
    },
  },
  methods: {
    checkPermission() {
      let {workStepResult, actionBy} = this.workResult
      if(!this.isEnabledDropdown) {
        let userRelateWork = [...this.workResult.workPermissionWorkOwner, ...this.workResult.workPermissionAreaOwner, ...this.workResult.workPermissionManager]

        this.workIdSelected = this.workResult.workId
        if(this.stateDropdownBtn) {
          return {
            type: 'showEditModal',
            description: 'เลือกการดำเนินการสำหรับแก้ไขข้อมูล',
            icon: 'Edit3Icon',
            pathName: ''
          }
        } else if (
            workStepResult.groupStatusType === 'approve' &&
            (
                ([2, 6].includes(this.workResult.workStep) && this.workResult.workPermissionWorkOwner.includes(this.userData.userId)) ||
                ([3, 7].includes(this.workResult.workStep) && this.workResult.workPermissionAreaOwner.includes(this.userData.userId)) ||
                ([4, 8].includes(this.workResult.workStep) && this.workResult.workPermissionManager.includes(this.userData.userId)) ||
                ([5, 9].includes(this.workResult.workStep) && workStepResult.permission.includes(this.userData.userGroupId))
            )
            // workStepResult.permission.includes(this.userData.userGroupId)
        ) {
          return {
            type: 'approval',
            description: 'รออนุมัติ',
            icon: 'CheckCircleIcon',
            pathName: 'pageWorkApproval'
          }
        } else if (workStepResult.groupStatusType === 'extendApprove' && workStepResult.permission.includes(this.userData.userGroupId) &&
            (userRelateWork.includes(this.userData.userId) || this.userData.userGroupId === 4)) {
          return {
            type: 'showModal',
            description: 'รออนุมัติขยายเวลาทำงาน',
            icon: 'PlusCircleIcon',
            pathName: ''
          }
        } else if (workStepResult.groupStatusType === 'edit') {
          return {
            type: 'edit',
            description: 'รอการแก้ไข',
            icon: 'SearchIcon',
            pathName: 'pageWorkView'
          }
        } else if (workStepResult.groupStatusType === 'inProgress' &&  workStepResult.permission.includes(this.userData.userGroupId) &&
            (userRelateWork.includes(this.userData.userId) || this.userData.userGroupId === 4)) {
          return {
            type: 'operation',
            description: 'อยู่ระหว่างดำเนินการ',
            icon: 'ActivityIcon',
            pathName: 'pageWorkOperation'
          }
        } else {
          return {
            type: 'view',
            description: 'ดูข้อมูล',
            icon: 'SearchIcon',
            pathName: 'pageWorkView'
          }
        }
      } else {
        return {
          type: '',
          description: '',
          icon: '',
          pathName: ''
        }
      }
    },
    goTo() {
      let result = this.checkPermission()
      if(result.type === 'showModal') {
        this.$refs['modal-choose-action'].show()
      } else if(result.type === 'showEditModal') {
        this.$refs['modal-choose-edit-action'].show()
      } else if(result.type === 'edit') {
        this.$router.push({name: 'pageWorkView', params: {id: this.workResult.workId}})
      } else {
        if(result.type !== 'edit' && result.pathName) {
          this.$router.push({name: result.pathName, params: {id: this.workResult.workId}})
        }
      }
    },
    goToEdit() {
      this.isEnabledDropdown = true
      this.$router.push({name: 'pageWorkEditStep1', params: {id: this.workResult.workId}})
    },
    confirmDelete() {
      this.isEnabledDropdown = true
      this.workIdSelected = this.workResult.workId
      this.$bvModal
          .msgBoxConfirm(this.$t('general.deleteDescription'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              let queryResult = await workService.deleteData(this.workIdSelected)
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.$emit('load-data')
                this.$refs['modal-choose-edit-action'].hide()
                this.$store.commit('main/setToastSuccess', {title: 'Delete success', text: ''})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
      return false
    }
  }
}
</script>

<style lang="scss" scoped>
.work-card {
  border: 2px solid transparent;
  transition: border 0.5s;
  text-decoration: none;
}

.work-card:hover {
  border: 2px solid #36008DFF;
  cursor: pointer;
}
</style>