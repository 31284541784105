<template>
  <div>
    <b-card :title="$t('work.search')">
      <b-form @submit.prevent="searchData">
        <b-row>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.department')">
              <v-select v-model="search.departmentId" label="departmentName"
                        :options="optionDepartments" :reduce="option => option.departmentId"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.location')">
              <v-select v-model="search.locationId" label="locationName"
                        :options="optionLocations" :reduce="option => option.locationId"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.status')">
              <v-select v-model="search.statusId" label="statusName"
                        :clearable="false"
                        :options="optionStatuses" :reduce="option => option.statusId"
              />
            </b-form-group>
          </b-col>

          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.startDate')">
              <flat-pickr v-model="search.workStartDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
            </b-form-group>
          </b-col>

          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.endDate')">
              <flat-pickr v-model="search.workEndDate" class="form-control" :config="$store.state.main.datePickerConfig"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row v-if="userData.userCategory === 1">
          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.company')">
              <v-select v-model="search.companyId" label="companyName"
                        :options="optionCompanies" :reduce="option => option.companyId"
              />
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.supervisor')">
              <b-form-input v-model="search.workContractorAdminName"/>
            </b-form-group>
          </b-col>
          <b-col md="6" lg="4">
            <b-form-group :label="$t('work.tel')">
              <b-form-input v-model="search.workContractorAdminTel"/>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="text-center">
            <b-button type="submit" variant="info" class="my-1 mr-1">
              <feather-icon icon="SearchIcon"/>
              {{ $t('general.btnSearch') }}
            </b-button>
          </b-col>
        </b-row>
      </b-form>

    </b-card>

    <b-row>
      <b-col class="text-right">
        <b-button
            type="button"
            variant="primary"
            :to="{name: 'pageWorkAddStep1'}"
            class="mb-2"
        >
          <feather-icon icon="PlusCircleIcon"/>
          {{ $t('general.btnAdd') }}
        </b-button>
      </b-col>
    </b-row>

    <b-overlay :show="isShowLoading">

      <b-row align-v="stretch">
        <b-col
            md="6"
            lg="4"
            v-for="(workResult, key) in workResults" :key="key"
        >

        <work-card :work-result="workResult" style="height: 90%" @load-data="listData"/>

        </b-col>
      </b-row>

<!--      <b-card :title="$t('work.list')">-->
        <!-- table -->
        <vue-good-table
            mode="remote"
            :columns="tableData.columns"
            :rows="tableData.rows"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
            :totalRows="tableData.totalRecords"
            :isLoading.sync="tableData.isLoading"
            :pagination-options="{ enabled: true }"
        >

          <!-- pagination -->
          <template
              slot="pagination-bottom"
              slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              {{ $t('general.tableShowing') }} 1 {{ $t('general.tableTo') }}
            </span>
                <b-form-select
                    v-model="tableData.pageLength"
                    :options="tableData.pageLengthOption"
                    class="mx-1"
                    @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> {{ $t('general.tableOf') }} {{ props.total }} {{ $t('general.tableEntries') }} </span>
              </div>
              <div>
                <b-pagination
                    :value="1"
                    :total-rows="props.total"
                    :per-page="tableData.pageLength"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
<!--      </b-card>-->
    </b-overlay>
  </div>

</template>

<script>
import {BAvatar, BButton, BCard, BCol, BForm, BFormGroup, BBadge, BFormInput, BFormSelect, BOverlay, BPagination, BRow, BCardGroup} from 'bootstrap-vue'
import {VueGoodTable} from 'vue-good-table'
import tableMixins from "@/mixins/tableMixins"
import masterMixins from "@/mixins/masterMixins"
import {WorkService} from "@/services"
import WorkCard from "@/components/work/WorkCard"

const workService = new WorkService()

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BBadge,
    BAvatar,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BOverlay,
    VueGoodTable,
    BCardGroup,
    WorkCard,
  },
  mixins: [tableMixins, masterMixins],
  async created() {
    let [listCompany, listPositions, listLocations, listDepartments] = await Promise.all([
      this.listCompany(),
      this.listPosition(),
      this.listLocation(),
      this.listDepartment(),
    ])
    this.optionCompanies = listCompany
    this.optionPositions = listPositions
    this.optionLocations = listLocations
    this.optionDepartments = listDepartments
    this.optionStatuses = this.listWorkStatus()

    let columns =  []

    if(this.userData.userCategory === 2) {
      columns = columns.filter(c => c.field !== 'companyName')
    }

    if(this.$route.query.workId) {
      this.search.workId = this.$route.query.workId
      this.search.statusId = 1
    }

    this.tableData.columns = columns
    this.onColumnFilter(this.search)
  },
  data: () => ({
    isShowLoading: true,
    pk: '',
    search: {
      workId: '',
      departmentId: '',
      locationId: '',
      statusId: 99,
      workStartDate: '',
      workEndDate: '',
      workContractorAdminName: '',
      workContractorAdminTel: '',
      companyId: '',
    },
    workResults: [],
    userData: JSON.parse(localStorage.getItem('userData')),
    optionDepartments: [],
    optionCompanies: [],
    optionLocations: [],
    optionStatuses: [],
    optionPositions: [],
  }),
  computed: {},
  watch: {
    tableChange() {
      this.listData()
    }
  },
  methods: {
    listData() {
      this.getTableQueryString()
      workService.listData(this.tableQueryString).then(queryResult => {
        let {isSuccess, data} = queryResult
        if (isSuccess) {
          this.workResults = data.results
          this.tableData.rows = data.results
          this.tableData.totalRecords = data.totalRow
        } else {
          this.$store.commit('main/setToastError', data)
        }
        this.isShowLoading = false
      })
    },
    searchData() {
      this.onColumnFilter(this.search)
    },
    deleteData(id) {
      this.$bvModal
          .msgBoxConfirm(this.$t('general.deleteDescription'), {
            title: this.$t('general.deleteTitle'),
            okVariant: 'primary',
            okTitle: this.$t('general.deleteConfirm'),
            cancelTitle: this.$t('general.deleteCancel'),
            cancelVariant: 'outline-secondary',
          })
          .then(async value => {
            if (value) {
              let queryResult = await userService.deleteData(id)
              let {isSuccess, data} = queryResult
              if (isSuccess) {
                this.isShowLoading = true
                this.listData()
                this.$store.commit('main/setToastSuccess', {title: 'Delete success', text: ''})
              } else {
                this.$store.commit('main/setToastError', data)
              }
            }
          })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>

